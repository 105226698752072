import * as AFRAME from 'aframe';
import innerCircleUrl from '../../../assets/img/Handle_Plain.png';
import outCircleUrl from '../../../assets/img/Handle_Outline.png';

interface IWorldButtonAframe {
    btnCallback: () => void;
    childPlane: any;
    el: AFRAME.Entity;
    sphere: AFRAME.Entity;
}

export interface WorldButtonAframeInstance extends IWorldButtonAframe {
    delayFrames: number;
    init(): void;
    activate(): void;
    deactivate(): void;
    move(x: number, y: number, z: number): void;
    setButtonCallback(callback: () => void): void;
}

const WorldButton = {
    name: 'world-button',
    val: {
        init(this: IWorldButtonAframe) {
            // Create a plane with the specified geometry
            const geometry = 'primitive: plane; width: 0.05; height: 0.05';
            this.el.setAttribute('geometry', geometry);
            this.el.setAttribute('material', { src: innerCircleUrl, shader: 'flat', color: 0xffffff, transparent: true });
            this.el.setAttribute('shadow', { cast: false, receive: false });

            // Create a child plane
            this.childPlane = document.createElement('a-plane');
            this.childPlane.setAttribute('width', 0.075);
            this.childPlane.setAttribute('height', 0.075);
            this.childPlane.setAttribute('material', { src: outCircleUrl, shader: 'flat', color: 0xffffff, transparent: true });
            this.childPlane.setAttribute('shadow', { cast: false, receive: false });
            this.el.appendChild(this.childPlane);

            // Add scale animation to the child plane
            this.childPlane.setAttribute('animation', {
              property: 'scale',
              dir: 'alternate',
              dur: 1000, // Animation duration in milliseconds
              easing: 'linear',
              loop: true,
              to: '1.2 1.2 1', // Target scale values
            });

            // increasing tap surface with a sphere
            this.sphere = document.createElement('a-sphere')
            this.sphere.setAttribute('radius', 0.075)
            this.sphere.setAttribute('material', {shader: 'flat', color: '#FF0000', alphaTest: 0.5, transparent: true, opacity: 0})
            this.sphere.setAttribute('segments-height', 12)
            this.sphere.setAttribute('segments-width', 12)
            this.sphere.setAttribute('shadow', { cast: false, receive: false });
            this.sphere.setAttribute('class', 'cantap')
            this.el.appendChild(this.sphere)

            this.btnCallback = () => {
              console.log('WORLD BUTTON: Default callback');
            };
            this.sphere.addEventListener('click', this.btnCallback);
        },
        activate(this: WorldButtonAframeInstance) {
          this.el.removeAttribute('animation');
          // Set new animation
          this.el.setAttribute('animation', {
            property: 'scale',
            dur: 400, // Animation duration in milliseconds
            easing: 'linear',
            loop: false,
            to: '1 1 1', // Target scale values
          });
        
          const setChildPlaneAnimation = () => {
            if (this.delayFrames-- <= 0) {
              this.childPlane.setAttribute('animation', {
                property: 'scale',
                dir: 'alternate',
                dur: 1000, // Animation duration in milliseconds
                easing: 'linear',
                loop: true,
                from: '1 1 1',
                to: '1.2 1.2 1', // Target scale values
              });
            } else {
              requestAnimationFrame(setChildPlaneAnimation);
            }
          };
          this.delayFrames = Math.round(400 / 16.7); // Assuming ~60fps, you might need to adjust this value
          requestAnimationFrame(setChildPlaneAnimation);
        },
        deactivate(this: IWorldButtonAframe) {
          // console.log('Deactivating')
          this.childPlane.removeAttribute('animation');
          this.el.removeAttribute('animation');
          //set new animation
          this.el.setAttribute('animation', {
            property: 'scale',
            dur: 400, // Animation duration in milliseconds
            easing: 'linear',
            loop: false,
            to: '0 0 1', // Target scale values
          });
        },
        move(this: WorldButtonAframeInstance, x: number, y: number, z: number) {
          this.deactivate();
          const activateWithDelay = () => {
            if (this.delayFrames-- <= 0) {
              this.el.object3D.position.set(x, y, z);
              this.activate();
            } else {
              requestAnimationFrame(activateWithDelay);
            }
          };
          this.delayFrames = 4; // Assuming ~60fps, you might need to adjust this value
          requestAnimationFrame(activateWithDelay);
          
        },
        setButtonCallback(this: IWorldButtonAframe, callback: any) {
          this.sphere.removeEventListener('click', this.btnCallback);
          this.btnCallback = callback;
          this.sphere.addEventListener('click', this.btnCallback);
        },
    },
};
export { WorldButton as WorldButtonComponent }