import './App.scss';
import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './views/home/home';
import { CarDemo } from 'views/car-demo/a-frame';
function App() {
  return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
                  path='/car-demo'
                  element={<CarDemo />}
                />
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
