import * as Aframe from 'aframe';
import * as THREE from 'three';
import { ICarSceneAFrame } from './car-scene-component';

export interface  JackControlAframe {
    putActions: THREE.AnimationAction[];
    raiseActions: THREE.AnimationAction[];
    playAction(arg0: THREE.AnimationAction): () => void;
    mixer: THREE.AnimationMixer;
    animatedEl: THREE.Object3D;
    jackPut(): () => void;
    jackRaise(): () => void;
    jackLosen(): () => void;
    jackRemove(): () => void;
    el: Aframe.Entity;
  }

  const JackControlComponent = {
    name: 'jack-control',
    val: {
        init(this :  JackControlAframe) {
            this.el.addEventListener('model-loaded', (e) => {
                this.el.setAttribute('visible', 'false');

                this.el.object3D.traverse((child: any) => {
                    if (child.animations.length > 0) {
                        this.animatedEl = child;
                    }
                });
                initialiseAnimations();
                e.stopPropagation();
            });

            const initialiseAnimations = () => {
                this.mixer = new THREE.AnimationMixer(this.animatedEl);
                // const [] = this.animatedEl.animations;
// 
                // this.actionLugWrench1 = this.mixer.clipAction(lugWrench1);
                // this.actionLugWrench2 = this.mixer.clipAction(lugWrench2);
                // this.actionLug1 = this.mixer.clipAction(lug1);
                // this.actionLug2 = this.mixer.clipAction(lug2);
                // this.actionLug3 = this.mixer.clipAction(lug3);
                // this.actionLug4 = this.mixer.clipAction(lug4);
                // this.actionLug5 = this.mixer.clipAction(lug5);
                // this.lugActions = [this.actionLug1, this.actionLug2, this.actionLug3, this.actionLug4, this.actionLug5];
            };
        },
        jackPut(this :  JackControlAframe) {
            console.log('Puttin jack')
            this.el.setAttribute('visible', 'true');
            this.putActions = [];
            for (let i =0; i < this.animatedEl.animations.length; i++) {
                if(i === 0 || i === 2 || i === 4 || i === 7 || i === 9 || i === 11 || i === 13 || i === 15 || i === 17) {
                    const action = this.mixer.clipAction(this.animatedEl.animations[i]);
                    this.putActions.push(action);
                }  
            }
            //play actions
            for (let i =0; i < this.putActions.length; i++) {
                this.playAction(this.putActions[i]);
            }
            const onAnimationComplete = () => {
                console.log('Animation complete');
                this.mixer.removeEventListener('finished', onAnimationComplete);
                const carScene = this.el.sceneEl?.components['car-scene'] as unknown as ICarSceneAFrame;
                carScene.stateComplete('JackPut');

            };

            this.mixer.addEventListener('finished', onAnimationComplete);
        },
        jackRaise(this :  JackControlAframe) {
            console.log('Raising jack')
            this.raiseActions = [];
            for (let i =0; i < this.animatedEl.animations.length; i++) {
                if(i === 1 || i === 3 || i === 5 || i === 8 || i === 10 || i === 12 || i === 14 || i === 16 || i === 18) {
                    const action = this.mixer.clipAction(this.animatedEl.animations[i]);
                    this.raiseActions.push(action);
                }  
            }
            //play actions
            for (let i =0; i < this.raiseActions.length; i++) {
                this.playAction(this.raiseActions[i]);
            }
        },
        jackLosen(this :  JackControlAframe) {
            console.log('Lowering jack')
            //play actions
            for (let i =0; i < this.raiseActions.length; i++) {
                this.raiseActions[i].timeScale = -1; // reverse animation
                this.playAction(this.raiseActions[i]);
            }

            const onAnimationComplete = () => {
                console.log('Animation complete jack losen');
                this.mixer.removeEventListener('finished', onAnimationComplete);
                this.jackRemove();
            };

            this.mixer.addEventListener('finished', onAnimationComplete);
        },
        jackRemove(this :  JackControlAframe) {
            // reverse actions
            for (let i =0; i < this.putActions.length; i++) {
                this.putActions[i].timeScale = -1; // reverse animation
                this.playAction(this.putActions[i]);
            }

            const onAnimationComplete = () => {
                console.log('Animation complete Jack remove');
                this.mixer.removeEventListener('finished', onAnimationComplete);
                // state finished, but need a delay because animation played backwards seem to emit a false 'finished' event
                setTimeout(() => {
                    const carScene = this.el.sceneEl?.components['car-scene'] as unknown as ICarSceneAFrame;
                    carScene.stateComplete('JackLosen');
                }, 3000);
            }

            this.mixer.addEventListener('finished', onAnimationComplete);
        },
        playAction(this : JackControlAframe, action: any) {
            action.reset();
            action.clampWhenFinished = true;
            action.repetitions = 1;
            action.play();
        },
        tick(this : JackControlAframe, time: number, timeDelta: number) {
            if (this.mixer) {
                this.mixer.update(timeDelta / 1000);
            }
        },
    },
};
export { JackControlComponent as JackControl }