import * as Aframe from 'aframe';

interface ILugsControlAframe {
    el: Aframe.Entity;
  }

  const LugsControlComponent = {
    name: 'lugs-control',
    val: {
        init(this : ILugsControlAframe) {
            
        },
        losenLugs(this : ILugsControlAframe) {
            this.el.object3D.traverse((child: any) => {
                if (child.name === 'lugs') {
                    child.rotation.x = 0.4;
                }
            });
        },
    },
};
export { LugsControlComponent as LugsControl }