import * as AFrame from 'aframe';
import { WorldButtonAframeInstance } from 'lib/aframe/components/world-button';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { IWrenchControlAframe, WrenchControl } from './wrench-control';
import { JackControlAframe } from './jack-control';

export interface ICarControl {
    actionRemoveWheel: THREE.AnimationAction;
    actionSecureWheel: THREE.AnimationAction;
    actionInstallWheel: THREE.AnimationAction;
    playActionSequence: any;
    actionsRemoveWheel: THREE.AnimationAction[];
    actionCarRaise: THREE.AnimationAction;
    actionBlockRear: THREE.AnimationAction;
    actionBlockFront: THREE.AnimationAction;
    actionRemoveSpareWheel: THREE.AnimationAction;
    actionRemoveTools: THREE.AnimationAction;
    actionBootCover: THREE.AnimationAction;
    actionBoot: THREE.AnimationAction;
    detailObj: { state: string; };
    stateCompleteEvent: CustomEvent<unknown>;
    setDriverDoorOpenButton(): unknown;
    setSaloonButton(): () => void;
    setBootButton(): () => void;
    setBootCoverButton(): () => void;
    setToolsButton(): () => void;
    setSpareWheelButton(): () => void;
    setBlockButton(): () => void;
    setLugsButton(): () => void;
    setJackPutButton(): () => void;
    setJackRaiseButton(): () => void;
    setRemoveWheelButton(): () => void;
    setInstallTireButton(): () => void;
    setJackLosenButton(): () => void;
    setTightenLugsButton(): () => void;
    setPackToolsButton(): () => void;
    setCompleteButton(): () => void;
    poolButtons: PoolComponent;
    actionDoor: THREE.AnimationAction;
    mixer: THREE.AnimationMixer;
    poolEntity: AFrame.Entity<AFrame.ObjectMap<AFrame.Component<any, AFrame.System<any>>>>;
    el: AFrame.Entity;
}

interface PoolComponent extends AFrame.Component {
    requestEntity(): AFrame.Entity | null;
    returnEntity(entity: AFrame.Entity): void;
}

  const CarControlComponent = {
    name: 'car-control',
    val: {
        init(this : ICarControl) {

            this.detailObj = {
                state: 'Default state complete message.',
            };
            this.stateCompleteEvent = new CustomEvent('state-complete', { detail: this.detailObj });

            this.el.addEventListener('model-loaded', (event) => {
                const modelEntity = event.target as AFrame.Entity;
                console.log('Some model loaded: ', modelEntity.id)
                if (modelEntity.id === 'car') {
                    console.log('Car model loaded')
                    this.el.object3D.traverse((child: any) => {
                        console.log(child)
                    });

                    initialiseAnimations();

                     //get pool entity
				    this.poolEntity = document.querySelector('[pool]') as AFrame.Entity;
				    // ony initialise buttons once pool has loaded
				    if (this.poolEntity.hasLoaded) {
				    	initialiseButtons();
				    } else {
				    	this.poolEntity.addEventListener('loaded', () => {
				    		initialiseButtons();
				    	});
				    }
                }
            })

            const initialiseAnimations = () => {
                const car = document.getElementById('car') as AFrame.Entity;
                let animatedEl = car?.object3D.getObjectByName('Car') as any;
                car.object3D.traverse((child: any) => {
                    if(child.animations.length >= 20) {
                        animatedEl = child;
                    }
                });
                
                console.log('Animated element: ', animatedEl);
                this.mixer = new THREE.AnimationMixer(animatedEl);
                const driverDoorClip = animatedEl.animations[6];
                const bootOpenClip = animatedEl.animations[8];
                const bootCoverRemoveClip = animatedEl.animations[1];
                const toolsRemoveClip = animatedEl.animations[11];
                const removeSpareWheelClip = animatedEl.animations[9];
                const blockFronClip = animatedEl.animations[19];
                const blockRearClip = animatedEl.animations[20];
                const carRaiseClip = animatedEl.animations[0];
                const installTireClip = animatedEl.animations[10];
                const wheelSecureClip = animatedEl.animations[17];
                this.actionDoor = this.mixer.clipAction(driverDoorClip);
                this.actionBoot = this.mixer.clipAction(bootOpenClip);
                this.actionBootCover = this.mixer.clipAction(bootCoverRemoveClip);
                this.actionRemoveTools = this.mixer.clipAction(toolsRemoveClip);
                this.actionRemoveSpareWheel = this.mixer.clipAction(removeSpareWheelClip);
                this.actionBlockFront = this.mixer.clipAction(blockFronClip);
                this.actionBlockRear = this.mixer.clipAction(blockRearClip);
                this.actionCarRaise = this.mixer.clipAction(carRaiseClip);
                const actionLug0 = this.mixer.clipAction(animatedEl.animations[12]);
                const actionLug1 = this.mixer.clipAction(animatedEl.animations[13]);
                const actionLug2 = this.mixer.clipAction(animatedEl.animations[14]);
                const actionLug3 = this.mixer.clipAction(animatedEl.animations[15]);
                const actionLug4 = this.mixer.clipAction(animatedEl.animations[16]);
                this.actionRemoveWheel = this.mixer.clipAction(animatedEl.animations[18]);
                this.actionsRemoveWheel = [actionLug0, actionLug1, actionLug2, actionLug3, actionLug4, this.actionRemoveWheel];
                this.actionInstallWheel = this.mixer.clipAction(installTireClip);
                this.actionSecureWheel = this.mixer.clipAction(wheelSecureClip);
            };

            const initialiseButtons = () => {
                this.poolButtons = this.poolEntity.components['pool'] as PoolComponent;
                this.setDriverDoorOpenButton();
            }
        },
        setDriverDoorOpenButton(this: ICarControl) {
            const doorTriggerBtn = this.poolButtons.requestEntity();
            if (!doorTriggerBtn) {
                console.log('No door trigger button');
                return;
            }
            doorTriggerBtn?.setAttribute('position', '1 0.95 0');
            doorTriggerBtn?.play();

            let isDoorClosed = true;
            if (doorTriggerBtn?.components['world-button']) {
                console.log('world button component present');
                const doorTriggerWB = doorTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
                if (doorTriggerWB) {
                    doorTriggerWB.setButtonCallback(() => {
                        if (isDoorClosed) {
                            // open the door
                            this.actionDoor.reset();
                            this.actionDoor.clampWhenFinished = true;
                            this.actionDoor.repetitions = 1;
                            this.actionDoor.timeScale = 1;
                            this.actionDoor.play();
                            doorTriggerWB.move(1.75, 1, 0.3);
                            isDoorClosed = false;
                            //fire event
                            this.detailObj.state = 'Doors';
                            this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                        } else {
                            //close the door
                            this.actionDoor.reset();
                            this.actionDoor.clampWhenFinished = true;
                            this.actionDoor.repetitions = 1;
                            this.actionDoor.timeScale = -1;
                            this.actionDoor.play();
                            doorTriggerWB.move(1, 0.95, 0);
                            isDoorClosed = true;
                        }
                    });
                }  else {
                    console.log('But still No door trigger WB');
                }
            } else { 
                console.log('No world button component present, adding listener');
                doorTriggerBtn.addEventListener('componentinitialized', (event) => {
                    // Check if the initialized component is 'world-button'
                    if (event.detail.name === 'world-button') {
                      const doorTriggerWB = doorTriggerBtn.components['world-button'] as unknown as WorldButtonAframeInstance;
                      console.log('Now world button component present');
                        if (doorTriggerWB) {
                            doorTriggerWB.setButtonCallback(() => {
                                if (isDoorClosed) {
                                    // open the door
                                    this.actionDoor.reset();
                                    this.actionDoor.clampWhenFinished = true;
                                    this.actionDoor.repetitions = 1;
                                    this.actionDoor.timeScale = 1;
                                    this.actionDoor.play();
                                    doorTriggerWB.move(1.2, 1, 0.35);
                                    isDoorClosed = false;
                                    //fire event
                                    this.detailObj.state = 'Doors';
                                    this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                                } else {
                                    //close the door
                                    this.actionDoor.reset();
                                    this.actionDoor.clampWhenFinished = true;
                                    this.actionDoor.repetitions = 1;
                                    this.actionDoor.timeScale = -1;
                                    this.actionDoor.play();
                                    doorTriggerWB.move(1, 1, 0);
                                    isDoorClosed = true;
                                }
                            });
                        }  else {
                            console.log('But still No door trigger WB');
                        }
                    }
                });
            }
            
        },
        setSaloonButton(this: ICarControl) {
            const saloonTriggerBtn = this.poolButtons.requestEntity();
            if (!saloonTriggerBtn) {
                console.log('No saloon trigger button');
                return;
            }
            saloonTriggerBtn?.setAttribute('position', '0.6 1 0.5');
            saloonTriggerBtn?.play();

            const saloonTriggerWB = saloonTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (saloonTriggerWB) {
                saloonTriggerWB.setButtonCallback(() => {
                    //fire event
                    this.detailObj.state = 'Saloon';
                    this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    saloonTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(saloonTriggerBtn);
                    }, 500);
                
                });
            } else {
                console.log('No saloon trigger WB');
            }
        },
        setBootButton(this: ICarControl) {
            const bootTriggerBtn = this.poolButtons.requestEntity();
            if (!bootTriggerBtn) {
                console.log('No boot trigger button');
                return;
            }
            bootTriggerBtn?.setAttribute('position', '0 1.15 -2.4');
            bootTriggerBtn?.play();

            const bootTriggerWB = bootTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (bootTriggerWB) {
                bootTriggerWB.setButtonCallback(() => {
                    // play animation to open boot
                    this.actionBoot.reset();
                    this.actionBoot.clampWhenFinished = true;
                    this.actionBoot.repetitions = 1;
                    this.actionBoot.timeScale = 1;
                    this.actionBoot.play();
                    //fire event
                    this.detailObj.state = 'Boot';
                    this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    //deactivate button
                    bootTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(bootTriggerBtn);
                    }, 500);
                    
                });
            } else {
                console.log('No boot trigger WB');
            }
        },
        setBootCoverButton(this: ICarControl) {
            const bootCoverTriggerBtn = this.poolButtons.requestEntity();
            if (!bootCoverTriggerBtn) {
                console.log('No boot cover trigger button');
                return;
            }
            bootCoverTriggerBtn?.setAttribute('position', '0.2 1.15 -2');
            bootCoverTriggerBtn?.play();

            const bootCoverTriggerWB = bootCoverTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (bootCoverTriggerWB) {
                bootCoverTriggerWB.setButtonCallback(() => {
                    // play animation to close boot
                    this.actionBootCover.reset();
                    this.actionBootCover.clampWhenFinished = true;
                    this.actionBootCover.repetitions = 1;
                    this.actionBootCover.timeScale = 1;
                    this.actionBootCover.play();
                    //fire event when animation finishes
                    //fire event when animation is finished
                    const onAnimationComplete = () => {
                        console.log('Animation complete');
                        this.mixer.removeEventListener('finished', onAnimationComplete);
                        this.detailObj.state = 'BootCover';
                        this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    };
        
                    this.mixer.addEventListener('finished', onAnimationComplete);

                    // deactivate button
                    bootCoverTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(bootCoverTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No boot cover trigger WB');
            }
        },
        setToolsButton(this: ICarControl) {
            const toolsTriggerBtn = this.poolButtons.requestEntity();
            if (!toolsTriggerBtn) {
                console.log('No tools trigger button');
                return;
            }
            toolsTriggerBtn?.setAttribute('position', '0 1.05 -2');
            toolsTriggerBtn?.play();

            const toolsTriggerWB = toolsTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (toolsTriggerWB) {
                toolsTriggerWB.setButtonCallback(() => {
                    // play animation
                    this.actionRemoveTools.reset();
                    this.actionRemoveTools.clampWhenFinished = true;
                    this.actionRemoveTools.repetitions = 1;
                    this.actionRemoveTools.timeScale = 1;
                    this.actionRemoveTools.play();
                    //fire event
                    //fire event when animation is finished
                    const onAnimationComplete = () => {
                        console.log('Animation complete');
                        this.mixer.removeEventListener('finished', onAnimationComplete);
                        this.detailObj.state = 'Tools';
                        this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    };
        
                    this.mixer.addEventListener('finished', onAnimationComplete);
                    // deactivate button
                    toolsTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(toolsTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No tools trigger WB');
            }
        },
        setSpareWheelButton(this: ICarControl) {
            const spareWheelTriggerBtn = this.poolButtons.requestEntity();
            if (!spareWheelTriggerBtn) {
                console.log('No spare wheel trigger button');
                return;
            }
            spareWheelTriggerBtn?.setAttribute('position', '0 0.9 -2');
            spareWheelTriggerBtn?.play();

            const spareWheelTriggerWB = spareWheelTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (spareWheelTriggerWB) {
                spareWheelTriggerWB.setButtonCallback(() => {
                    // play animation
                    this.actionRemoveSpareWheel.reset();
                    this.actionRemoveSpareWheel.clampWhenFinished = true;
                    this.actionRemoveSpareWheel.repetitions = 1;
                    this.actionRemoveSpareWheel.timeScale = 1;
                    this.actionRemoveSpareWheel.play();
                    //fire event
                    this.detailObj.state = 'SpareWheel';
                    this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    // deactivate button
                    spareWheelTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(spareWheelTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No spare wheel trigger WB');
            }
        },
        setBlockButton(this: ICarControl) {
            const blockTriggerBtn = this.poolButtons.requestEntity();
            if (!blockTriggerBtn) {
                console.log('No block trigger button');
                return;
            }
            blockTriggerBtn?.setAttribute('position', '-1.1, 0.75, 1');
            blockTriggerBtn?.play();

            const blockTriggerWB = blockTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (blockTriggerWB) {
                blockTriggerWB.setButtonCallback(() => {
                    // play animation front
                    this.actionBlockFront.reset();
                    this.actionBlockFront.clampWhenFinished = true;
                    this.actionBlockFront.repetitions = 1;
                    this.actionBlockFront.timeScale = 1;
                    this.actionBlockFront.play();
                    // play anmation rear simultaneously
                    this.actionBlockRear.reset();
                    this.actionBlockRear.clampWhenFinished = true;
                    this.actionBlockRear.repetitions = 1;
                    this.actionBlockRear.timeScale = 1;
                    this.actionBlockRear.play();
                    //fire event
                    this.detailObj.state = 'Block';
                    this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    // deactivate button
                    blockTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(blockTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No block trigger WB');
            }
        },
        setLugsButton(this: ICarControl) {
            const lugsTriggerBtn = this.poolButtons.requestEntity();
            if (!lugsTriggerBtn) {
                console.log('No lugs trigger button');
                return;
            }
            lugsTriggerBtn?.setAttribute('position', '1.1, 0.75, -1.25');
            lugsTriggerBtn?.play();

            const lugsTriggerWB = lugsTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (lugsTriggerWB) {
                lugsTriggerWB.setButtonCallback(() => {
                    const wrench = document.getElementById('wrench') as AFrame.Entity;
                    const wrenchControl = wrench.components['wrench-control'] as unknown as IWrenchControlAframe;
                    wrenchControl.losenLugs();
                    //fire event
                    // this.detailObj.state = 'Lugs';
                    // this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    // deactivate button
                    lugsTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(lugsTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No lugs trigger WB');
            }
        },
        setJackPutButton(this: ICarControl) {
            const jackPutTriggerBtn = this.poolButtons.requestEntity();
            if (!jackPutTriggerBtn) {
                console.log('No jack put trigger button');
                return;
            }
            jackPutTriggerBtn?.setAttribute('position', '1.0, 0.55, -0.7');
            jackPutTriggerBtn?.play();

            const jackPutTriggerWB = jackPutTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (jackPutTriggerWB) {
                jackPutTriggerWB.setButtonCallback(() => {
                    const jack = document.getElementById('jack') as AFrame.Entity;
                    const jackControl = jack.components['jack-control'] as unknown as JackControlAframe;
                    jackControl.jackPut();
                    //fire event
                    // this.detailObj.state = 'JackPut';
                    // this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    // deactivate button
                    jackPutTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(jackPutTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No jack put trigger WB');
            }
        },
        setJackRaiseButton(this: ICarControl) {
            const jackRaiseTriggerBtn = this.poolButtons.requestEntity();
            if (!jackRaiseTriggerBtn) {
                console.log('No jack raise trigger button');
                return;
            }
            jackRaiseTriggerBtn?.setAttribute('position', '1.0, 0.55, -0.7');
            jackRaiseTriggerBtn?.play();

            const jackRaiseTriggerWB = jackRaiseTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (jackRaiseTriggerWB) {
                jackRaiseTriggerWB.setButtonCallback(() => {
                    const jack = document.getElementById('jack') as AFrame.Entity;
                    const jackControl = jack.components['jack-control'] as unknown as JackControlAframe;
                    const wrench = document.getElementById('wrench') as AFrame.Entity;
                    const wrenchControl = wrench.components['wrench-control'] as unknown as IWrenchControlAframe;
                    // play animation on tools
                    wrenchControl.playJackWrenchAction();
                    const delayInMilliseconds = 2500; // You can set this to the desired delay
                    setTimeout(() => {
                        jackControl.jackRaise();

                        // raise car animation
                        this.actionCarRaise.reset();
                        this.actionCarRaise.clampWhenFinished = true;
                        this.actionCarRaise.repetitions = 1;
                        this.actionCarRaise.timeScale = 1;
                        this.actionCarRaise.play();
                    }, delayInMilliseconds);

                    //fire event when animation is finished
                    const onAnimationComplete = () => {
                        console.log('Animation complete');
                        this.mixer.removeEventListener('finished', onAnimationComplete);
                        this.detailObj.state = 'JackRaise';
                        this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    };
        
                    this.mixer.addEventListener('finished', onAnimationComplete);
                    
                    // deactivate button
                    jackRaiseTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(jackRaiseTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No jack raise trigger WB');
            }
        },
        setRemoveWheelButton(this: ICarControl) {
            const removeWheelTriggerBtn = this.poolButtons.requestEntity();
            if (!removeWheelTriggerBtn) {
                console.log('No remove wheel trigger button');
                return;
            }
            removeWheelTriggerBtn?.setAttribute('position', '1.1, 0.75, -1.25');
            removeWheelTriggerBtn?.play();

            const removeWheelTriggerWB = removeWheelTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (removeWheelTriggerWB) {
                removeWheelTriggerWB.activate();
                removeWheelTriggerWB.setButtonCallback(() => {
                    
                    this.playActionSequence(0, this.actionsRemoveWheel, 'RemoveWheel');
                    // deactivate button
                    removeWheelTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(removeWheelTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No remove wheel trigger WB');
            }
        },
        setInstallTireButton(this: ICarControl) {
            const installTireTriggerBtn = this.poolButtons.requestEntity();
            if (!installTireTriggerBtn) {
                console.log('No install tire trigger button');
                return;
            }
            installTireTriggerBtn?.setAttribute('position', '1.1, 0.75, -1.25');
            installTireTriggerBtn?.play();

            // setup new aimation sequence
            this.actionsRemoveWheel.pop() // remove last action (istalling the wheel)
            // reverse lug actions
            for (let i = 0; i < this.actionsRemoveWheel.length; i++) {
                const action = this.actionsRemoveWheel[i];
                action.timeScale = -1;
            }
            // add install tire action to the beginning
            this.actionsRemoveWheel.unshift(this.actionInstallWheel);
            // note that animation of removing spare from the boot has to be reset as it affects spare wheel postiion
            this.actionRemoveSpareWheel.stop();

            const installTireTriggerWB = installTireTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (installTireTriggerWB) {
                installTireTriggerWB.activate();
                installTireTriggerWB.setButtonCallback(() => {
                    // note that the animation sequence is modified and reversed
                    this.playActionSequence(0, this.actionsRemoveWheel, 'InstallTire');
                    // deactivate button
                    installTireTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(installTireTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No install tire trigger WB');
            }
        },
        setJackLosenButton(this: ICarControl) {
            const jackLosenTriggerBtn = this.poolButtons.requestEntity();
            if (!jackLosenTriggerBtn) {
                console.log('No jack losen trigger button');
                return;
            }
            jackLosenTriggerBtn?.setAttribute('position', '1.0, 0.55, -0.7');
            jackLosenTriggerBtn?.play();

            const jackLosenTriggerWB = jackLosenTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (jackLosenTriggerWB) {
                jackLosenTriggerWB.activate();
                jackLosenTriggerWB.setButtonCallback(() => {
                    const jack = document.getElementById('jack') as AFrame.Entity;
                    const jackControl = jack.components['jack-control'] as unknown as JackControlAframe;
                    jackControl.jackLosen();
                    const wrench = document.getElementById('wrench') as AFrame.Entity;
                    const wrenchControl = wrench.components['wrench-control'] as unknown as IWrenchControlAframe;
                    wrenchControl.playJackWrenchAction(true);
                    //lower car animation
                    this.actionCarRaise.reset();
                    this.actionCarRaise.timeScale = -1;
                    this.actionCarRaise.play();
                    
                    // deactivate button
                    jackLosenTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(jackLosenTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No jack losen trigger WB');
            }
        },
        setTightenLugsButton(this: ICarControl) {
            const tightenLugsTriggerBtn = this.poolButtons.requestEntity();
            if (!tightenLugsTriggerBtn) {
                console.log('No tighten lugs trigger button');
                return;
            }
            tightenLugsTriggerBtn?.setAttribute('position', '1.1, 0.75, -1.3');
            tightenLugsTriggerBtn?.play();

            const tightenLugsTriggerWB = tightenLugsTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (tightenLugsTriggerWB) {
                tightenLugsTriggerWB.activate();
                tightenLugsTriggerWB.setButtonCallback(() => {
                    const wrench = document.getElementById('wrench') as AFrame.Entity;
                    const wrenchControl = wrench.components['wrench-control'] as unknown as IWrenchControlAframe;
                    wrenchControl.playLugAction(0, true);
                    // deactivate button
                    tightenLugsTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(tightenLugsTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No tighten lugs trigger WB');
            }
        },
        setPackToolsButton(this: ICarControl) {
            const packToolsTriggerBtn = this.poolButtons.requestEntity();
            if (!packToolsTriggerBtn) {
                console.log('No pack tools trigger button');
                return;
            }
            packToolsTriggerBtn?.setAttribute('position', '0.2 1.05 -2');
            packToolsTriggerBtn?.play();

            const packToolsTriggerWB = packToolsTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (packToolsTriggerWB) {
                packToolsTriggerWB.activate();
                packToolsTriggerWB.setButtonCallback(() => {
                    // remove tools
                    const wrench = document.getElementById('wrench') as AFrame.Entity;
                    wrench.setAttribute('visible', false);
                    const jack = document.getElementById('jack') as AFrame.Entity;
                    jack.setAttribute('visible', false);
                    // create new animation sequence
                    const actionSequence = [];
                    actionSequence.push(this.actionSecureWheel);
                    this.actionRemoveWheel.stop(); // have to stop previous wheel animation to not affect the new one
                    // play sequence of animations with some ugly workarounds, because normal animations are not ready
                    this.actionSecureWheel.reset();
                    this.actionSecureWheel.clampWhenFinished = true;
                    this.actionSecureWheel.repetitions = 1;
                    this.actionSecureWheel.play();
                    //fire event when animation is finished
                    let counter = 0;
                    const onAnimationComplete = () => {
                        console.log('Animation complete');
                        this.mixer.removeEventListener('finished', onAnimationComplete);
                        if(counter === 0) {
                            // move wheel lover
                            const spare = this.el.object3D.getObjectByName('WheelRearLeft') as THREE.Object3D;
                            if (spare) {
                                const startPosition = new THREE.Vector3().copy(spare.position);
                                const targetPosition = new THREE.Vector3(spare.position.x, spare.position.y - 0.125, spare.position.z);

                                const tween = new TWEEN.Tween(startPosition)
                                  .to(targetPosition, 500)
                                  .easing(TWEEN.Easing.Quadratic.Out)
                                  .onUpdate(() => {
                                    spare.position.copy(startPosition);
                                  })
                                  .onComplete(() => {
                                    onAnimationComplete();
                                  })
                                  .start();
                                }
                        } else if (counter === 1) {
                            // move boot cover
                            this.actionBootCover.reset();
                            this.actionBootCover.timeScale = -1;
                            this.actionBootCover.play();
                            this.mixer.addEventListener('finished', onAnimationComplete);
                        } else if (counter === 2) {
                            // move tools
                            const tools = this.el.object3D.getObjectByName('Tool_Case') as THREE.Object3D;
                            if (tools) {
                                const startPosition = new THREE.Vector3().copy(tools.position);
                                const targetPosition = new THREE.Vector3(tools.position.x + 0.75, tools.position.y - 0.075, tools.position.z + 1);

                                const tween = new TWEEN.Tween(startPosition)
                                  .to(targetPosition, 1000)
                                  .easing(TWEEN.Easing.Quadratic.Out)
                                  .onUpdate(() => {
                                    tools.position.copy(startPosition);
                                  })
                                  .onComplete(() => {
                                    onAnimationComplete();
                                  })
                                  .start();
                            } else {
                                console.log('No tools');
                            }
                        } else if (counter === 3) {
                            console.log('Emitting Pc#ackTools comlete');
                            this.detailObj.state = 'PackTools';
                            this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                        }
                        counter++;
                    };
        
                    this.mixer.addEventListener('finished', onAnimationComplete);

                    // this.actionBootCover.timeScale = -1;
                    // actionSequence.push(this.actionBootCover);
                    // this.actionRemoveTools.timeScale = -1;
                    // actionSequence.push(this.actionRemoveTools);
                    // this.playActionSequence(0, actionSequence, 'PackTools');
                    // deactivate button
                    packToolsTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(packToolsTriggerBtn);
                    }, 500);
                });
            } else {
                console.log('No pack tools trigger WB');
            }
        },
        setCompleteButton(this: ICarControl) {
            const completeTriggerBtn = this.poolButtons.requestEntity();
            if (!completeTriggerBtn) {
                console.log('No complete trigger button');
                return;
            }
            completeTriggerBtn?.setAttribute('position', '0 1.75 -2.6');
            completeTriggerBtn?.play();

            const completeTriggerWB = completeTriggerBtn?.components['world-button'] as unknown as WorldButtonAframeInstance;
            if (completeTriggerWB) {
                completeTriggerWB.activate();
                completeTriggerWB.setButtonCallback(() => {
                    // close boot
                    this.actionBoot.reset();
                    this.actionBoot.timeScale = -1;
                    this.actionBoot.play();
                    // deactivate button
                    completeTriggerWB.deactivate();
                    setTimeout(() => {
                        this.poolButtons.returnEntity(completeTriggerBtn);
                        // remove info panel 
                        this.detailObj.state = 'Complete';
                        this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
                    }, 500);
                });
            } else {
                console.log('No complete trigger WB');
            }
        },
        playActionSequence(this: ICarControl, currentIndex: number, actions: THREE.AnimationAction[], stateWhenFinished: string) {
            if (currentIndex < actions.length) {
                const action = actions[currentIndex];
                action.reset();
                action.clampWhenFinished = true;
                action.repetitions = 1;
                action.play();
    
                const onAnimationComplete = () => {
                    console.log('Animation complete');
                    this.mixer.removeEventListener('finished', onAnimationComplete);
                    this.playActionSequence(currentIndex + 1, actions, stateWhenFinished);
                };
    
                this.mixer.addEventListener('finished', onAnimationComplete);
            } else {
                this.detailObj.state = stateWhenFinished;
                this.el.sceneEl?.dispatchEvent(this.stateCompleteEvent);
            }
        },
        tick(this: ICarControl, time: number, deltaTime: number) {
            this.mixer?.update(deltaTime * 0.001);
            TWEEN.update();
        },
    },
};
export { CarControlComponent as CarControl }