import * as Aframe from 'aframe';
import * as THREE from 'three';

export interface IInfoPanelAframe {
    proximityDIstance: number;
    plane: any;
    setText(arg0: string): unknown;
    setPosition(x: number, y: number, z: number): void;
    setRotation(x: number, y: number, z: number): void;
    setProximityDistance(distance: number): void;
    deactivate(): () => void;
    camera: Aframe.Entity | null;
    panelWorldPosition: THREE.Vector3;
    text: Aframe.Entity;
    el: Aframe.Entity;
  }

  const InfoPanelComponent = {
    name: 'info-panel',
    val: {
        init(this : IInfoPanelAframe) {
            const panelImg = require('../../assets/img/InfoBlock.png');
            // Create a plane with a semi-transparent texture
            this.plane = document.createElement('a-plane');
            this.plane.setAttribute('material', `src: url(${panelImg}); transparent: true`);
            this.plane.setAttribute('width', '0.5');
            this.plane.setAttribute('height', '0.25');
            this.plane.setAttribute('scale', '1 1 1');
            // this.plane.setAttribute('face-camera', '');

            // Create a text element that sits on top of the plane
            // this.text = document.createElement('a-text');
            // this.text.setAttribute('value', 'Info panel and here come some more text just see test the oveflwo');
            // this.text.setAttribute('align', 'left');
            // this.text.setAttribute('color', '#0b5394'); // Change the text color to grey
            // this.text.setAttribute('width', '0.4'); // Set the text width to be slightly smaller than the plane's width
            // this.text.setAttribute('height', '0.2'); // Set the text height to be slightly smaller than the plane's height
            // // this.text.setAttribute('scale', '1 1 1'); // Adjust the scale to make the text smaller
            // this.text.setAttribute('wrapCount', '12'); // Set the wrap count to fit the text within the plane
            // this.text.setAttribute('position', '-0.2 0.08 0.02'); // Slightly in front of the plane to avoid z-fighting

            // Create an entity with the text component that sits on top of the plane
            this.text = document.createElement('a-entity');
            this.text.setAttribute('text', `
              value: Info panel and here come some more text just see test the oveflwo;
              align: center;
              color: #0b5394;
              width: 0.2;
              height: 0.2;
              wrapCount: 24
            `);
            this.text.setAttribute('position', '-0.01 -0.005 0.02'); // Slightly in front of the plane to avoid z-fighting

            // Add the plane and text to the info-panel entity
            this.el.appendChild(this.plane);
            this.plane.appendChild(this.text);

            this.camera = document.querySelector('a-camera');
            this.proximityDIstance = 1.5; //disance from camera to show the panel
            this.panelWorldPosition = new THREE.Vector3();
            
        },
        setText(this: IInfoPanelAframe, text: string) {
            this.text.setAttribute('text', `value: ${text}; align: center; anchor: center; color: #0b5394; width: 0.42; height: 0.2; wrapCount: 24`);
        },
        setPosition(this: IInfoPanelAframe, x: number, y: number, z: number) {
            this.plane.setAttribute('position', `${x} ${y} ${z}`);
        },
        setRotation(this: IInfoPanelAframe, x: number, y: number, z: number) {
            this.plane.setAttribute('rotation', `${x} ${y} ${z}`);
        },
        setProximityDistance(this: IInfoPanelAframe, distance: number) {
            this.proximityDIstance = distance;
        },
        deactivate(this: IInfoPanelAframe) {
            this.plane.setAttribute('visible', false);
        },
        tick(this: IInfoPanelAframe) {
            if (this.camera) {
              const cameraPosition = this.camera.object3D.position;
              this.plane.object3D.getWorldPosition(this.panelWorldPosition);
              const distance = cameraPosition.distanceTo(this.panelWorldPosition);
          
              const isVisible = distance <= this.proximityDIstance && distance >= 0.1; // Adjust the distance value as needed
              this.plane.setAttribute('visible', isVisible);
            }
        },
    },
};
export { InfoPanelComponent as InfoPanel }