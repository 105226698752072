import React from 'react'
import * as AFRAME from 'aframe'
import { AFrameScene, DISABLE_IMAGE_TARGETS } from '../../lib/aframe-component'
import { FaceCameraComponent } from '../../lib/aframe/components/face-camera'
import { WorldButtonComponent } from '../../lib/aframe/components/world-button'
import { CarScene } from './car-scene-component'
import { CarControl } from './car-control-component'
import IconButton from 'lib/lesson-ui/icon-button'
import recentrIcon from '../../assets/img/icons/crosshair.svg'
import { InfoPanel } from './info-panel-component'
import { LugsControl } from './lugs-control'
import { JackControl } from './jack-control'
import { WrenchControl } from './wrench-control'


const CarDemo = (() => {
    const onRecenterClick = () => {
        const ascene = document.getElementsByTagName('a-scene')[0];
        if(ascene) {
            // emit event to recenter camera
            const aframeScene = ascene as AFRAME.Scene;
            aframeScene.emit('recenter');
        }
    };
    
    return(
        <React.Fragment>
            <IconButton onClick={onRecenterClick} imgSrc={recentrIcon}/>
            <AFrameScene
                sceneHtml={require('./car-aframe-scene.html')}
                imageTargets={DISABLE_IMAGE_TARGETS}
                components={[CarScene, CarControl, WorldButtonComponent, FaceCameraComponent, InfoPanel, LugsControl, JackControl, WrenchControl]}
            />
        </React.Fragment>)
})
export { CarDemo }