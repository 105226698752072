import * as AFrame from 'aframe';
import {IInfoPanelAframe} from './info-panel-component';
import { ICarControl } from './car-control-component';

export interface ICarSceneAFrame {
    stateComplete(state: any): () => void;
    carControl: ICarControl;
    currentState: string;
    infoPanel: IInfoPanelAframe;
    changeState(arg0: string): unknown;
    el: AFrame.Entity;
  }

  const CarSceneComponent = {
    name: 'car-scene',
    val: {
        init(this : ICarSceneAFrame) {
            const sceneEl = this.el.sceneEl
            const car = document.getElementById('car') as AFrame.Entity;
            this.infoPanel = car.components['info-panel'] as unknown as IInfoPanelAframe;
            this.carControl = car.components['car-control'] as unknown as ICarControl;

            this.changeState('Doors');

            sceneEl?.addEventListener('state-complete', (event) => {
              const e = event as CustomEvent;
              this.stateComplete(e.detail.state);
            });

            sceneEl?.addEventListener('coaching-overlay.show', () => {
                car.setAttribute('scale', '0.001 0.001 0.001')
            });
    
            sceneEl?.addEventListener('coaching-overlay.hide', () => {
              console.log('Coaching overlay hide')
              car.setAttribute('scale', '1 1 1')
            });
            // window.XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded)
        },
        changeState(this: ICarSceneAFrame, newState: string) {
          switch (newState) {
            case 'Doors':
              this.infoPanel.setText('Open doors to get access.');
              this.currentState = 'Doors';
              this.infoPanel.setPosition(1.1, 1.25, 0.0);
              this.infoPanel.setRotation(0, 90, 0);
              break;
      
            case 'Saloon':
              this.currentState = 'Saloon';
              this.carControl.setSaloonButton();
              this.infoPanel.setText('Put the vehicle in park, apply the parking brake, shut the vehicle off and turn on the hazard lights.');
              this.infoPanel.setPosition(0.6, 1.1, 0.15);
              this.infoPanel.setRotation(0, 90, 0);
              // Handle hidden state logic here
              break;
      
            case 'Boot':
              this.currentState = 'Boot';
              this.carControl.setBootButton();
              this.infoPanel.setText('Open the boot to get access.');
              this.infoPanel.setPosition(0, 1.4, -2.4);
              this.infoPanel.setRotation(0, 180, 0);
              // Handle visible state logic here
              break;

            case 'BootCover':
              this.currentState = 'BootCover';
              this.carControl.setBootCoverButton();
              this.infoPanel.setText('Remove boot cover to get access to tools and spare wheel.');
              this.infoPanel.setPosition(0, 1.15, -1.75);
              this.infoPanel.setRotation(-15, 180, 0);
              break;

            case 'Tools':
              this.currentState = 'Tools';
              this.carControl.setToolsButton();
              this.infoPanel.setText('Remove wheel lug wrench, jack and jack handle. Note the location of toolset varies by vehicle.');
              break;

            case 'SpareWheel':
              this.currentState = 'SpareWheel';
              this.carControl.setSpareWheelButton();
              this.infoPanel.setText('Remove spare wheel from the boot.');
              break;

            case 'Block':
              this.currentState = 'Block';
              this.carControl.setBlockButton();
              this.infoPanel.setText('Block both the front and the rear of the tire diagonally opposite the tire you are changing.');
              this.infoPanel.setPosition(-1.2, 1, 1);
              this.infoPanel.setRotation(-10, -135, 0);
              this.infoPanel.setProximityDistance(3.0); // wheel blocking info is detectable on onger distance
              // this.infoPanel.setRotation(0, -90, 0);
              break;

            case 'Lugs':
              this.currentState = 'Lugs';
              this.carControl.setLugsButton();
              this.infoPanel.setText('Losen each of the lug nuts in a star pattern (as shown). Only losen one turn and do not remove the lug nuts.');
              this.infoPanel.setPosition(1.1, 1, -1.25);
              this.infoPanel.setRotation(-15, 90, 0);
              this.infoPanel.setProximityDistance(1.5);// back to defaults
              break;

            case 'JackPut':
              this.currentState = 'JackPut';
              this.carControl.setJackPutButton();
              this.infoPanel.setText('Put the jack in the designated jacking area, closest to the tire you are changing. The position is detectable by tho plates welded together with notches. Fix the jack.');
              this.infoPanel.setPosition(1.1, 0.85, -0.7);
              break;
            
            case 'JackRaise':
              this.currentState = 'JackRaise';
              this.carControl.setJackRaiseButton();
              this.infoPanel.setText('Insert jack handle into the jack and turn it clockwise raising the vehicle until the tire clears the ground. Make sure the vehicle is stable on the jack.');
              break;

            case 'RemoveWheel':
              this.currentState = 'RemoveWheel';
              this.carControl.setRemoveWheelButton();
              this.infoPanel.setText('Losen and remove the lug nuts. Then remove the wheel from the studs and place it on the ground.');
              this.infoPanel.setPosition(1.1, 1, -1.25);
              break;

            case 'InstallTire':
              this.currentState = 'InstallTire';
              this.carControl.setInstallTireButton();
              this.infoPanel.setText('Install the spare tire onto the studs. Install and tighten the lug nuts onto the studs with your fingers.');
              break;

            case 'JackLosen':
              this.currentState = 'JackLosen';
              this.carControl.setJackLosenButton();
              this.infoPanel.setText('Lower the vehicle to the ground by turning the jack handle counter-clockwise. Remove the jack.');  
              this.infoPanel.setPosition(1.1, 0.85, -0.7);
              break;

            case 'TightenLugs':
              this.currentState = 'TightenLugs';
              this.carControl.setTightenLugsButton();
              this.infoPanel.setText('Tighten the lug nuts in a star pattern as shown. After tightening once in the pattern, check each nut once more in the same pattern.');
              this.infoPanel.setPosition(1.1, 1, -1.30);
              break;

            case 'PackTools':
              this.currentState = 'PackTools';
              this.carControl.setPackToolsButton();
              this.infoPanel.setText('Secure the flat tire and place the jack and tools in a safe, secure area in your vehicle.');
              this.infoPanel.setPosition(0, 1.15, -1.75);
              this.infoPanel.setRotation(-15, 180, 0);
              break;

            case 'Complete':
              this.currentState = 'Complete';
              this.carControl.setCompleteButton();
              this.infoPanel.setText('You have successfully changed a flat tire. While it will allow you to continue driving, Hyundai recommends you replace your tire as soon as possible.');
              break;
            
            case 'Empty':
              this.currentState = 'Empty';
              this.infoPanel.deactivate();
              break;

            default:
              console.error(`Invalid state: ${newState}`);
              break;
          }
        },
        stateComplete(this: ICarSceneAFrame, state: string) {
          console.log('State complete event: ', state);
          if (state === 'Doors' && this.currentState === 'Doors') {
            this.changeState('Saloon');
          } else if (state === 'Saloon' && this.currentState === 'Saloon') {
            this.changeState('Boot');
          } else if (state === 'Boot' && this.currentState === 'Boot') {
            this.changeState('BootCover');
          } else if (state === 'BootCover' && this.currentState === 'BootCover') {
            this.changeState('Tools');
          } else if (state === 'Tools' && this.currentState === 'Tools') {
            this.changeState('SpareWheel');
          } else if (state === 'SpareWheel' && this.currentState === 'SpareWheel') {
            this.changeState('Block');
          } else if (state === 'Block' && this.currentState === 'Block') {
            this.changeState('Lugs');
          } else if (state === 'Lugs' && this.currentState === 'Lugs') {
            this.changeState('JackPut');
          } else if (state === 'JackPut' && this.currentState === 'JackPut') {
            this.changeState('JackRaise');
          } else if (state === 'JackRaise' && this.currentState === 'JackRaise') {
            this.changeState('RemoveWheel');
          } else if (state === 'RemoveWheel' && this.currentState === 'RemoveWheel') {
            this.changeState('InstallTire');
          } else if (state === 'InstallTire' && this.currentState === 'InstallTire') {
            this.changeState('JackLosen');
          } else if (state === 'JackLosen' && this.currentState === 'JackLosen') {
            this.changeState('TightenLugs');
          } else if (state === 'TightenLugs' && this.currentState === 'TightenLugs') {
            this.changeState('PackTools');
          } else if (state === 'PackTools' && this.currentState === 'PackTools') {
            this.changeState('Complete');
          } else if (state === 'Complete' && this.currentState === 'Complete') {
            this.changeState('Empty');
          }
        }
    },
};
export { CarSceneComponent as CarScene }