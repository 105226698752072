import * as Aframe from 'aframe';
import * as THREE from 'three';
import { ICarSceneAFrame } from './car-scene-component';

export interface IWrenchControlAframe {
    lugIndex: number;
    lugActions: any[];
    animationCompleteListener: () => void;
    playAction(action: any): () => void;
    playLugAction(index: number, isInverse?: boolean): unknown;
    playJackWrenchAction(isInverted?: boolean): () => void;
    actionLug5: THREE.AnimationAction;
    actionLug4: THREE.AnimationAction;
    actionLug3: THREE.AnimationAction;
    actionLug2: THREE.AnimationAction;
    actionLug1: THREE.AnimationAction;
    actionLugWrench2: THREE.AnimationAction;
    actionLugWrench1: THREE.AnimationAction;
    mixer: THREE.AnimationMixer;
    animatedEl: any;
    el: Aframe.Entity;
    losenLugs: () => void;
  }

  const WrenchControlComponent = {
    name: 'wrench-control',
    val: {
        init(this : IWrenchControlAframe) {
            this.el.addEventListener('model-loaded', (e) => {
                this.el.setAttribute('visible', 'false');

                this.el.object3D.traverse((child: any) => {
                    if (child.animations.length > 0) {
                        this.animatedEl = child;
                    }
                });
                initialiseAnimations();
                e.stopPropagation();
            });

            const initialiseAnimations = () => {
                this.mixer = new THREE.AnimationMixer(this.animatedEl);
                const [lugWrench1, lug1, lug2, lug3, lug4, lug5, lugWrench2] = this.animatedEl.animations;

                this.actionLugWrench1 = this.mixer.clipAction(lugWrench1);
                this.actionLugWrench2 = this.mixer.clipAction(lugWrench2);
                this.actionLug1 = this.mixer.clipAction(lug1);
                this.actionLug2 = this.mixer.clipAction(lug2);
                this.actionLug3 = this.mixer.clipAction(lug3);
                this.actionLug4 = this.mixer.clipAction(lug4);
                this.actionLug5 = this.mixer.clipAction(lug5);
                this.lugActions = [this.actionLug1, this.actionLug2, this.actionLug3, this.actionLug4, this.actionLug5];
            };
        },
        losenLugs(this : IWrenchControlAframe) {
            console.log('WRENCH-CONTROL: Losening lugs.')
            this.el.setAttribute('visible', 'true');
            this.playLugAction(0);
            
        },
        playLugAction(this: IWrenchControlAframe, currentIndex: number, isInverse: boolean = false) {
            if (currentIndex < this.lugActions.length) {
                const action = this.lugActions[currentIndex];
                action.reset();
                if (isInverse) {
                    action.timeScale = -1;
                    // reset previous animations
                    this.actionLugWrench1.stop();
                    this.actionLugWrench2.stop();
                }
                action.repetitions = 1;
                action.play();
    
                const onAnimationComplete = () => {
                    console.log('Animation complete');
                    this.mixer.removeEventListener('finished', onAnimationComplete);
                    this.playLugAction(currentIndex + 1, isInverse);
                };
    
                this.mixer.addEventListener('finished', onAnimationComplete);
            } else {
                const carScene = this.el.sceneEl?.components['car-scene'] as unknown as ICarSceneAFrame;
                if(isInverse) {
                    carScene.stateComplete('TightenLugs');
                } else {
                    carScene.stateComplete('Lugs');
                }
                
            }
        },
        playAction(this : IWrenchControlAframe, action: any) {
            action.reset();
            action.repetitions = 1;
            action.clampWhenFinished = true  // note all actions pay using this method will be clamped 
            action.play();
        },
        playJackWrenchAction(this : IWrenchControlAframe, isInverted: boolean = false) {
            if (isInverted) {
                this.actionLugWrench1.timeScale = -1;
                this.actionLugWrench2.timeScale = -1;
            }
            this.el.setAttribute('visible', 'true');
            this.playAction(this.actionLugWrench1);
            this.playAction(this.actionLugWrench2);
        },
        tick (this : IWrenchControlAframe, time: number, deltaTime: number) {
            if (this.mixer) {
                this.mixer.update(deltaTime * 0.001);
            }
        },
    },
};
export { WrenchControlComponent as WrenchControl }